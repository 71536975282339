<template>
    <dashboardLayout>
        <navigation-container :navigation-data="navData"
                              :btn="{title: 'Назад', route: 'sportsmans'}">

            <wrap-section class="!bg-danger-light" v-if="store.state?.permissions?.includes('public_create_sportsman') === false">
                <div class="text-center">
                    У вас нет прав для создания спорсмена
                </div>
            </wrap-section>

            <wrap-section class="!bg-danger-light" v-else-if="store.state?.userStatus === 'moderating'">
                <div class="text-center">
                    Ваша учётная запись на проверке
                </div>
            </wrap-section>



            <div class="flex justify-center mb-10" v-else-if="store.state?.userStatus === 'active'">

                <div class="w-full lg:w-auto">
                    <div class="prof_container relative flex">
                        <wrap-section :is-empty="true">
                            <h2 class="text-2xl md:text-4xl ">Добавить нового спортсмена</h2>
                            <p class="text-base mt-5">Заполните данные для добавления нового спортсмена в систему</p>
                        </wrap-section>
                    </div>
                    <div class="prof_container relative flex" id="photo">
                        <wrap-section>
                            <span class="text-dark-base text-xl font-bold">Фото</span><br><br>
                            <div class="flex flex-wrap sm:flex-nowrap">
                                <div v-if="fd.avatar"
                                     class="mr-5 flex items-center justify-center">
                                    <img :src="fd.avatar" alt="" class="w-28 h-28 rounded-full">
                                </div>
                                <div class="w-full">
                                    <span class="font-bold">Загрузите фотографию</span> <br>
                                    <span class="text-base">В форматах JPG, PNG</span>
                                    <div class="mt-3">
                                        <file-upload uploadType="sportsmanAvatar" message="Ошибка при загрузке Аватара"
                                                     :return-url="true" :new-user="true"/>
                                    </div>
                                </div>
                            </div>
                        </wrap-section>
                    </div>

                    <div class="prof_container mt-8" id="personal">
                        <wrap-section>

                            <span class="text-dark-base text-xl font-bold">Персональные данные</span><br><br>
                            <input-custom label="Имя*" type="text" v-model="fd.name" class="mt-4 "
                                          labelClass="pass-update"/>
                            <input-custom label="Отчество" type="text" v-model="fd.middle_name" class="mt-4 "
                                          labelClass="pass-update"/>
                            <input-custom label="Фамилия*" type="text" v-model="fd.surname" class="mt-4 "
                                          labelClass="pass-update"/>

                            <div class="wrap_age_gender mt-4">
                                <date-picker v-model="fd.birthday" label="Дата рождения*" label-class="min-w-32 font-bold"/>
                                <div class="mt-4 gender sm:mt-0 d_center">
                                    <gender v-model="fd.sex" label-class="font-bold"/>
                                </div>
                            </div>
                            <div class="mt-4 ">
                                <dadata-city :location="fd.location"/>
                            </div>

                            <div class="mt-8">
                                <div v-if="!fd.personalDoc">
                                    <div class="text-base font-bold">Загрузите документ для подтверждения личности*
                                    </div>
                                    <div class="text-base mb-2">Паспорт или свидетельство о рождении в форматах JPG, PNG</div>
                                    <file-upload uploadType="personalDoc"
                                                 message="Ошибка при загрузке Документа удостоверяющего личность"
                                                 :return-url="true" :new-user="true"/>
                                    <div class="text-xs mt-2">*Документ после проверки будет удален с платформы</div>
                                </div>

                                <div class="flex justify-between" v-else>
                                    <div>
                                        Вы загрузили документ удостоверящий личность
                                    </div>
                                    <div class="cursor-pointer" @click="deletePersonalDoc">
                                        <icon icon-name="delete"> </icon>
                                    </div>
                                </div>
                            </div>

                            <organisations class="mt-7"/>
                        </wrap-section>

                    </div>

                    <wrap-section :is-empty="true">
                        <alerts alert-type="newSportsman"/>
                    </wrap-section>

                    <div class="prof_container relative flex">
                        <wrap-section :is-empty="true" class="sm:flex sm:justify-between sm:mt-0 ">
                            <button class="btn mt-3 btn_blue w-full sm:w-64" @click="saveSportsman">
                                Создать спортсмена
                            </button>
                            <button class="btn mt-3 btn_blue w-full sm:w-64 "
                                    @click="sentToModeration" >
                                Отправить на модерацию
                            </button>
                        </wrap-section>
                    </div>
                </div>

            </div>



            <wrap-section class="!bg-danger-light" v-else>
                <div class="text-center">
                    Вы не можете добавлять спортсменов, пока не пройдете верификацию <br>
                    Для прохождения верификации заполните <span class="text-base-color cursor-pointer" @click="navigateTo('profile')">все поля в профайле</span>

                </div>
            </wrap-section>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
//
import dashboardLayout from "@/views/layout/dashboard";
import {apiRequest, navigateTo, personalDataUpdate} from "@/utils/helpers";
import Icon from "@/views/components/helpers/icon.vue";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import FileUpload from "@/views/components/dashboard/fileUpload.vue";
import store from "@/store";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import smoothScroll from "@/utils/smoothScroll";
import useEmitter from "@/utils/useEmitter";
import DatePicker from "@/views/components/inputComponent/datePicker.vue";
import Gender from "@/views/components/inputComponent/gender.vue";
import dadataCity from "@/views/components/dashboard/dadataCity.vue";
import {onMounted, ref} from "vue";
import multiSelect from "../../../components/inputComponent/multiSelect.vue";
import Organisations from "@/views/components/dashboard/organisations.vue";
import Directions from "@/views/components/dashboard/directions.vue";
import addNewItemButton from "@/views/components/helpers/addNewItemButton";
import {LOGIN, USERS_DATA, USER_DATA} from "@/router/api";
import Alerts from "@/views/components/helpers/alerts.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import router from "@/router/router";
import {useRoute} from "vue-router";

const route = useRoute();
const emitter = useEmitter();

const fd = ref({
   name: '',
   avatar: '',
   personalDoc: '',
   middle_name: '',
   surname: '',
   birthday: '',
   sex: '',
   role: 'sportsman',
   location: {},
   organisation: {},
   direction: {},
   uuid: route.params.id,
   new_user: true,
   moderation: true,
});

onMounted(async () => {
   const personalData = store.getters.getUserInfo;
   fd.value.location = {"city": personalData.city, "country": personalData.country, "region": personalData.region};
   fd.value.organisation = store.getters.getUserOrganisations;
   fd.value.direction = store.getters.getDirections;
});

emitter.on('address', value => {
   fd.value.location = value;
})
emitter.on('birthday', value => {
   fd.value.birthday = value;
})
emitter.on('returnUrl', value => {
   if (value && value.url && value.uploadType === 'sportsmanAvatar') {
       fd.value.avatar = value.url;
   }
});

const verify = () => {
   let base = 'Проверьте корректность ввода поля '
   if (!fd.value.name) return emitter.emit("alert", {alertType: 'newSportsman', message: base + 'Имя'});
   if (!fd.value.surname) return emitter.emit("alert", {alertType: 'newSportsman', message: base + 'Фамилия'});
   if (!fd.value.birthday) return emitter.emit("alert", {alertType: 'newSportsman', message: base + 'Дата Рождения'});
   if (!fd.value.sex) return emitter.emit("alert", {alertType: 'newSportsman', message: base + 'Пол'});
   if (!fd.value.location?.city) return emitter.emit("alert", {alertType: 'newSportsman', message: base + 'Город'});
   // if (!fd.value.personalDoc) return emitter.emit("alert", {
   //     alertType: 'newSportsman',
   //     message: 'Загрузите документ подтверждающий личность спортсмена'
   // });


   return true;
}

const deletePersonalDoc = () => {
   fd.value.personalDoc = '';
}

const saveSportsman = async () => {
   // TODO: добавить валидацию
   if (verify()) {
       await apiRequest(USERS_DATA, fd.value, 'post')
           .then(async resp => {
               if (resp && resp.success) {
                   await personalDataUpdate();
                   return emitter.emit("success", {
                       alertType: 'newSportsman',
                       message: "Спортсмен создан успешно и отправлен на модерацию"
                   });
               }
               return emitter.emit("alert", {alertType: 'newSportsman', message: "Ошибка сервера"});
           })
           .catch(e => {
               if (e.error && e.message) {
                   return emitter.emit("alert", {alertType: 'newSportsman', message: e.message});
               }
               return emitter.emit("alert", {alertType: 'newSportsman', message: "Ошибка сервера"});
           })
   }

}

const sentToModeration = async () => {
    if (verify()) {

        if (!fd.value.personalDoc) {
            return emitter.emit("alert", {
                alertType: 'personalData',
                message: 'Загрузите документ удостоверяющий личность ' + (isEditSportsman ? "спортсмена" : ''),
            });
        }
        fd.value.moderation = true;
        await saveSportsman();
    }
}

const navData = [
   {name: 'Фото', id: 'photo'},
   {name: 'Персональные данные', id: 'personal'},
   // {name: 'email', id: 'E-mail'},
];

emitter.on('returnUrl', value => {
   if (value.uploadType === 'avatar') fd.value.avatar = value.url;
   if (value.uploadType === 'personalDoc') fd.value.personalDoc = value.url;
})
emitter.on('organisation', value => {
   fd.value.organisation = value;
})

emitter.on('direction', value => {
   if (value) {
       let res = JSON.parse(value);
       if (res.id) {
           fd.value.direction_id = res.id;
       }
   }
})

</script>

