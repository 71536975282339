<template>
    <dashboardLayout>
        <navigation-container class="mt-10">

            <wrap-section v-if="event">
                <img class="rounded-lg" :src="event.banner_url" alt="">

                <h1 v-if="event.title" class="text-2xl font-normal text-left pt-5">
                    {{ event.title }}
                </h1>

                <h2 v-if="event.direction.name" class="text-lg font-semibold text-dark-base pt-5">
                    {{ event.direction.name }}
                </h2>

                <div class="sm:flex sm:justify-between pt-5">
                    <div class="grid grid-cols-1 sm:grid-cols-2 items-end">
                        <div class="text-sm font-bold leading-none my-2 sm:my-0">Начало соревнований:</div>
                        <div class="text-base leading-none">
                            <icon icon-name="calendar" class="inline-block sm:ml-6 mr-2 -mb-1"/>
                            {{ formatDate(eventStartAt) }}
                        </div>
                    </div>
                    <div class="grid grid-cols-1 sm:grid-cols-2 items-end">
                        <div class="text-sm font-bold leading-none my-2 sm:my-0">Конец соревнований</div>
                        <div class="text-base leading-none">
                            <icon icon-name="calendar" class="inline-block sm:ml-6 mr-2 -mb-1"/>
                            {{ formatDate(eventEndAt) }}
                        </div>
                    </div>
                </div>
                <div class="sm:flex pt-5 items-end">
                    <div class="text-sm font-bold">Место проведения:</div>
                    <div class="text-base">
                        <icon icon-name="mapPin" class="inline-block sm:ml-6 mr-2 -mb-1"/>
                        {{ event.place_info }}
                    </div>
                </div>

                <div class="pt-5">
                    <div class="text-sm font-bold">Организатор:</div>
                    <div class="text-base mt-1">
                        {{ event.organizer }}
                    </div>
                </div>

                <div v-if="event.organizer" class="pt-5">
                    <div class="text-sm font-bold">Соорганизатор:</div>
                    <div class="text-base mt-1">
                        <!-- {{ event.co_organizer }} -->
                        <span v-if="event.co_organizer" v-for="(coOrg, index) in event.co_organizer.split(/[,.;]+/)" :key="index">
                            {{ coOrg.trim() }}<span v-if="index < event.co_organizer.split(/[,.;]+/).length - 1">,</span><br />
                        </span>
                    </div>
                </div>

                <div class="pt-5">
                    <p class="text-sm">{{ event.description }}</p>
                </div>

                <timer :registrationStartAt="registrationStartAt" :registrationEndAt="registrationEndAt"
                       :eventStartAt="eventStartAt" :eventEndAt="eventEndAt"
                />


                <div class="sm:flex sm:justify-between pt-5">
                    <div class="grid grid-cols-1 sm:grid-cols-2 items-end">
                        <div class="text-sm font-bold">Начало регистрации:</div>
                        <div class="sm:ml-6 text-base">
                            {{ formatDate(registrationStartAt) }}
                        </div>
                    </div>
                    <div class="grid grid-cols-1 sm:grid-cols-2 items-end">
                        <div class="text-sm font-bold">Конец регистрации:</div>
                        <div class="sm:ml-6 text-base">
                            {{ formatDate(registrationEndAt) }}
                        </div>
                    </div>
                </div>



            </wrap-section>
    
            <wrap-section v-if="isRegistrationActive
            && store.state?.permissions?.includes('public_trainer_pay_events')" :is-empty="true" class="d_center">
                <button class="btn btn_blue w-full mt-5 mb-5 sm:w-auto pl-5 pr-5"
                        @click="router.push({name: 'event-registration', params: {id: event.uuid}})">
                    Регистрация на соревнование
                </button>
            </wrap-section>

            <wrap-section v-if="event">

                <div v-if="event.conditions_info" class="pb-5">
                    <h2 class="text-lg font-semibold text-dark-base">
                        Правила участия в соревновании
                    </h2>
                    <p class="text-sm pt-5">
                        {{ event.conditions_info }}
                    </p>
                </div>

                <h2 class="text-lg font-semibold text-dark-base">
                    Стоимость участия
                </h2>

                <div>
                    <div class="sm:flex items-end pt-2.5" v-for="el in event?.price">
                        <div class="text-sm font-bold leading-none mb-2 sm:mb-0" v-if="el.direction_category?.title">
                            {{ el.direction_category?.title }}:
                        </div>
                        <div class="sm:pl-2.5 leading-none">
                            {{ parseFloat(el.price).toLocaleString('ru-Ru') }} ₽
                        </div>
                    </div>
                </div>

            </wrap-section>

            <wrap-section v-if="event">

                <h2 class="text-lg font-semibold text-dark-base">
                    Представители
                    <span v-if="event.represent.length"
                          class="ml-5 text-icon-base">{{ event.represent.length }}</span>
                </h2>

                <div class="sm:flex flex-wrap" v-if="event.represent">
                    <div v-for="el in event.represent" class="w-full mt-5 md:w-1/2">
                        <represent-item class="pt-5" :data="el"/>
                    </div>

                </div>

            </wrap-section>

            <wrap-section v-if="event">

                <h2 class="text-lg font-semibold text-dark-base">
                    Вопросы и ответы
                </h2>

                <div class="divide-y divide-solid divide-light-border">

                    <faq-card
                        v-for="(item, index) in event.faq"
                        :key="index"
                        :question="item.question"
                        :answer="item.answer"
                    />
                </div>

            </wrap-section>

            <wrap-section v-if="event">
                <h2 class="text-lg font-semibold text-dark-base">
                    Файлы
                </h2>

                <div class="flex flex-wrap justify-between">
                    <file-item v-for="(item, index) in event.files" :key="index" :data="item"/>
                </div>
            </wrap-section>

            <wrap-section v-if="event && event.registration_end_at < new Date().toISOString()">
                <h2 class="text-lg font-semibold text-dark-base">
                    Расписание
                </h2>

                <div class="mt-5" v-if="!event.schedule?.data">
                    Вы еще не составляли расписание.
                </div>

                <div v-else>
                    <div class="hidden sm:block">
                        <schedule-p-c :data="event.schedule?.data"/>
                    </div>
                    <div class="sm:hidden">
                        <schedule-mob :data="event.schedule?.data"/>
                    </div>
                </div>
            </wrap-section>

        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import {EVENTS} from "@/router/api";
import {apiRequest, navigateTo} from "@/utils/helpers";
import {onMounted, ref, computed} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from 'vue-router';
import dashboardLayout from "@/views/layout/dashboard";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import icon from "@/views/components/helpers/icon.vue";
import faqCard from "@/views/components/competitions/faqCard.vue";
import timer from "@/views/components/competitions/timer.vue";
import RepresentItem from "@/views/components/competitions/representItem.vue";
import FileItem from "@/views/components/competitions/fileItem.vue";
import router from "@/router/router";
import CompStatus from "@/views/components/competitions/compStatus.vue";
import CompUpload from "@/views/components/competitions/compUpload.vue";
import SchedulePC from "@/views/components/competitions/schedulePC.vue";
import ScheduleMob from "@/views/components/competitions/scheduleMob.vue";
import store from "@/store";

const route = useRoute();
const emitter = useEmitter();
const event = ref(null);
const competitionId = ref(route.params.id);
const registrationStartAt = ref(null);
const registrationEndAt = ref(null);
const eventStartAt = ref(null);
const eventEndAt = ref(null);


const isRegistrationActive = computed(() => {
    const now = new Date();
    const registrationEnd = new Date(registrationEndAt.value);
    return registrationEnd > now;
});


onMounted(async () => {
    await competition();
    if (event.value && typeof event.value.questions === 'string') {
        event.value.questions = JSON.parse(event.value.questions);
    }
});
const competition = async () => {
    await apiRequest(`${EVENTS}/${competitionId.value}`, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp && resp.success && resp.result) {
                event.value = resp.result
                registrationStartAt.value = resp.result.registration_start_at
                registrationEndAt.value = resp.result.registration_end_at
                eventStartAt.value = resp.result.start_at
                eventEndAt.value = resp.result.end_at
            }
        })
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ru-RU");
};



</script>
