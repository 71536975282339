<template>
    <div class="mx-auto p-0.5 mb-2">
        <div class="text-xs text-base-color pl-2 pt-1">Участник</div>
        <div class="flex">
            <div class="w-2/3 pl-2 h-auto">
                <div class="w-full mb-1" v-if="data.sportsmen.length" v-for="sport in data.sportsmen" :key="sport.id">
                    <span class="text-sm font-semibold leading-5" v-if="isSingleSportsman">
                        {{sport.full_name}}
                    </span>
                    <span v-else class="text-xs font-normal leading-4 px-4 py-1 rounded-xl bg-light-border">
                        {{sport.full_name}}
                    </span>
                </div>
                <div class="w-full">
                    <div class="text-xs font-normal leading-4 mt-2">
                        {{ formattedData }}
                    </div>
                </div>
            </div>
            <div class="w-1/3 pr-2 bg-blue-400">
                <comp-status :data="data" />
            </div>
        </div>

        <div class="flex flex-wrap items-stretch">
            <div class="w-1/3 p-1 sm:p-2 h-full">
                <div class="text-xs text-base-color pl-2 pt-1 min-h-9 mb-2">Аудио</div>
                <comp-upload :data="data" uploadType="audio"/>
            </div>
            <div class="w-1/3 p-1 sm:p-2 h-full">
                <div class="text-xs text-base-color pl-2 pt-1 min-h-9 mb-2">Бланк сложности</div>

                <comp-upload v-if="isSingleSportsman" :data="data" uploadType="elements" />
                <comp-upload v-else :data="data" uploadType="elements" icon="uploadArchive" />

            </div>
            <div class="w-1/3 p-1 sm:p-2 h-full">
                <div class="text-xs text-base-color pl-2 pt-1 min-h-9 mb-2">Мед. справка</div>
                <comp-upload :data="data" uploadType="medicalCertificate" />
            </div>
            <div class="w-full flex justify-center my-2">
                <hr class="w-4/5 border-t my-1 border-light-border" />
            </div>
            <div class="w-1/2 p-1 sm:p-2 h-full">
                <div class="text-xs text-base-color pl-2 pt-1 mb-2">Страховка</div>
                <comp-upload :data="data" uploadType="insurance"/>
            </div>
            <div class="w-1/2 p-1 sm:p-2 h-full">
                <div class="text-xs text-base-color pl-2 pt-1 mb-2">Разрешение</div>
                <comp-upload :data="data" uploadType="permission" />
            </div>
        </div>
        <hr class="w-full border-t my-2" />
    </div>

</template>

<script setup>
import CompStatus from "@/views/components/competitions/compStatus.vue";
import CompUpload from "@/views/components/competitions/compUpload.vue";
import {defineProps, computed} from "vue";

const props = defineProps({
    data: {
        type: Object,
        required: true
    },
    isSingleSportsman: {
        type: Boolean,
        default: false
    }
});

// это нужно, чтобы дважды не выводить данные, если передаётся более одного спортика
const formattedData = computed(() => {
    const parts = [];
    if (props.data.equipment) parts.push(props.data.equipment);
    if (props.data.direction_category) parts.push(props.data.direction_category);
    if (props.data.program) parts.push(props.data.program);
    if (props.data.level) parts.push(props.data.level);
    return parts.join(', ');
});

</script>
