let API_URL = '';
if (process.env.NODE_ENV === 'production') {
    switch (window.location.hostname) {
        case 'platform.app-nebojump.ru':
            API_URL = 'https://platform-api.app-nebojump.ru';
            break;
        case 'sport.wafed.org':
            API_URL = 'https://admin.wafed.org';
            break;
    }
} else {
    API_URL = process.env.VUE_APP_BACKEND_ADDRESS;
}

const AUTH_STATUS = API_URL + '/auth/status';
const LOGIN = API_URL + '/login';
const FORGOT_PASSWORD = API_URL + '/forgot-password';
const RESET_PASSWORD = API_URL + '/reset-password';
const LOGOUT = API_URL + '/logout';
const PASSWORD = API_URL + '/api/password';
const REGISTER = API_URL + '/register';
const GET_USER_BY_ECHAMP_ID = API_URL + '/get-user-by-echamp-id';
const ECHAMP_USER_UPDATE_EMAIL = API_URL + '/echamp-user-update-email';
const SANCTUM = API_URL + '/sanctum/csrf-cookie';
const USERS_DATA = API_URL + '/api/users';
const USER_DATA = API_URL + '/api/users/current';
const SPORTSMAN = API_URL + '/api/sportsman';
const TRAINERS = API_URL + '/api/trainers';
const SPORTSMAN_SEARCH = API_URL + '/api/sportsman/search';
const SPORTSMANS = API_URL + '/api/sportsmans';
const ALL_SPORTSMANS_SEARCH = API_URL + '/api/sportsmans/search';
const REPRESENT = API_URL + '/api/event-represent';
const SPORTSMANS_FILES_UPLOAD = API_URL + '/api/registration-file-upload';
const FILES = API_URL + '/api/files';
const ROLES = API_URL + '/api/roles';
const ORGANISATIONS = API_URL + '/api/organisations';
const ORGANISATIONS_SEARCH = API_URL + '/api/organisations/search';
const VERIFICATION = API_URL + '/api/users/verification';
const DIRECTIONS = API_URL + '/api/directions';
const REGISTRATION_OPTIONS = API_URL + '/api/event-registration/options';
const PROGRAMS = API_URL + '/api/programs';
const EQUIPMENTS = API_URL + '/api/equipments';
const LEVELS = API_URL + '/api/levels';
const DIRECTION_CATEGORIES = API_URL + '/api/direction_categories';
const AGE_GROUPS = API_URL + '/api/age-groups';

const REGISTRATION = API_URL + '/api/event-registration';
const EVENT_UNIT =  API_URL + '/api/event-unit';
const EVENT_PAYMENT =  API_URL + '/api/event-payment';
const EVENT_PAYMENT_SUCCESS =  API_URL + '/api/event-payment/success';
const REG_FILES = API_URL + '/api/registration-files';
const EVENTS = API_URL + '/api/events';
const LOGIN_AS_USER = API_URL + '/api/users/login-as-user';
const EVENT_FAQ = API_URL + '/api/event-faq';
const EVENT_DOC = API_URL + '/api/event-doc';

export {
    AUTH_STATUS,
    LOGIN,
    LOGOUT,
    SANCTUM,
    USER_DATA,
    USERS_DATA,
    REGISTER,
    GET_USER_BY_ECHAMP_ID,
    ECHAMP_USER_UPDATE_EMAIL,
    FILES,
    PASSWORD,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    ORGANISATIONS,
    ORGANISATIONS_SEARCH,
    VERIFICATION,
    ROLES,
    TRAINERS,
    SPORTSMAN,
    SPORTSMAN_SEARCH,
    SPORTSMANS,
    ALL_SPORTSMANS_SEARCH,
    SPORTSMANS_FILES_UPLOAD,
    DIRECTIONS,
    REGISTRATION_OPTIONS,
    LOGIN_AS_USER,
    PROGRAMS,
    EQUIPMENTS,
    LEVELS,
    DIRECTION_CATEGORIES,
    AGE_GROUPS,

    REGISTRATION,
    EVENT_UNIT,
    EVENT_PAYMENT,
    EVENT_PAYMENT_SUCCESS,
    REG_FILES,
    EVENTS,
    EVENT_FAQ,
    EVENT_DOC,
    REPRESENT
};