<template>
    <input-custom label="Название*" type="text" v-model="fd.title" class="mt-4 mob_margin" labelClass="pass-update"/>
    <!-- <directions class="mt-5" title="Вид спорта*" :multiple="false" :direction="props.data?.direction"/> -->

    <div class="wrap_age_gender mt-4">
        <date-picker v-model="fd.start_at" label="Дата начала соревнований*" label-class="sm:w-32 font-bold"
                     :future="true" type="startAt"/>
        <div class="w-full mt-4 gender sm:mt-0 sm:flex sm:justify-center">
            <date-picker v-model="fd.end_at" label="Дата окончания соревнований*" label-class="sm:w-32 font-bold"
                         :future="true" type="endAt"/>
        </div>
    </div>

    <input-custom label="Место проведения*" type="text" v-model="fd.place_info" class="mt-4 mob_margin"
                  labelClass="pass-update !whitespace-normal"/>

    <text-area-custom label="Описание" v-model="fd.description" class="mt-4 mob_margin" labelClass="pass-update"/>

    <input-custom label="Организатор" type="text" v-model="fd.organizer" class="mt-4 mob_margin" labelClass="pass-update"/>

    <input-custom v-if="fd.organizer" label="Соорганизатор" type="text" v-model="fd.co_organizer" class="mt-4 mob_margin" labelClass="pass-update"/>

    <div class="wrap_age_gender mt-4">
        <date-picker v-model="fd.registration_start_at" label="Дата начала регистрации*" label-class="sm:w-32 font-bold"
                     :future="true" type="regStartAt"/>
        <div class="mt-4 gender sm:mt-0 sm:flex sm:justify-center">
            <date-picker v-model="fd.registration_end_at" label="Дата окончания регистрации*"
                         label-class="sm:w-32 font-bold" :future="true" type="regEndAt"/>
        </div>
    </div>
    <div class="wrap_age_gender mt-5">
        <time-picker :model-value="fd.time_reg_start"
                     label="Время начала регистрации (по Москве)" type="timeRegStart" label-class="sm:w-32 font-bold"/>
        <div class="mt-4 gender sm:mt-0 sm:flex sm:justify-center">
            <time-picker :model-value="fd.time_reg_end"
            label="Время окончания регистрации (по Москве)" type="timeRegEnd" label-class="sm:w-32 font-bold"/>
        </div>

    </div>


    <alerts alertType="competitionInfo"/>

    <button class="btn mt-8 btn_blue w-full sm:w-64 " @click="saveCompInfo">
        Сохранить
    </button>


</template>

<script setup>
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import {ref, watch} from "vue";
import DatePicker from "@/views/components/inputComponent/datePicker.vue";
import useEmitter from "@/utils/useEmitter";
import Alerts from "@/views/components/helpers/alerts.vue";
import Directions from "@/views/components/dashboard/directions.vue";
import TextAreaCustom from "@/views/components/inputComponent/textAreaCustom.vue";
import {apiRequest} from "@/utils/helpers";
import {EVENTS} from "@/router/api";
import {useRoute} from "vue-router";
import TimePicker from "@/views/components/inputComponent/timePicker.vue";
import moment from 'moment';
import 'moment-timezone';

const props = defineProps({
    data: {
        type: Object,
        default: {},
    },
})

const route = useRoute();
const emitter = useEmitter();
const isLoading = ref(false);

emitter.on('startAt', value => {
    if (!value) return;
    fd.value.start_at = value;
});
emitter.on('endAt', value => {
    if (!value) return;
    fd.value.end_at = value;
});
emitter.on('regStartAt', value => {
    if (!value) return;
    fd.value.registration_start_at = value;
});
emitter.on('regEndAt', value => {
    if (!value) return;
    fd.value.registration_end_at = value;
});
emitter.on('timeRegStart', value => {
    if (!value) return;
    fd.value.time_reg_start = value;
});
emitter.on('timeRegEnd', value => {
    if (!value) return;
    fd.value.time_reg_end = value;
});
emitter.on('direction', value => {
    if (!value) return;

    let res = JSON.parse(value);
    if (res.id) {
        fd.value.direction_id = res.id;
    }

});

const fd = ref({
    type: 'competitionInfo',
    title: '',
    direction_id: '',
    start_at: '',
    end_at: '',
    registration_start_at: '',
    registration_end_at: '',
    description: '',
    place_info: '',
    organizer: '',
    co_organizer: '',
    time_reg_start: {
        hours: 0,
        minutes: 0,
        seconds: 0,
    },
    time_reg_end: {
        hours: 0,
        minutes: 0,
        seconds: 0,
    }
});


const validation = () => {
    let base = 'Проверьте корректность ввода поля '
    let _base = 'Заполните поле '
    if (fd.value.title.length < 2) return emitter.emit("alert", {
        alertType: 'competitionInfo',
        message: base + 'Название'
    });
    // if (!fd.value.direction_id) return emitter.emit("alert", {
    //     alertType: 'competitionInfo',
    //     message: "Укажите Вид спорта"
    // });
    if (!fd.value.start_at) return emitter.emit("alert", {
        alertType: 'competitionInfo',
        message: _base + 'Дата начала соревнований'
    });
    if (!fd.value.registration_start_at) return emitter.emit("alert", {
        alertType: 'competitionInfo',
        message: _base + 'Дата начала регистрации'
    });
    if (!fd.value.end_at) return emitter.emit("alert", {
        alertType: 'competitionInfo',
        message: _base + 'Дата окончания соревнований'
    });
    if (!fd.value.registration_end_at) return emitter.emit("alert", {
        alertType: 'competitionInfo',
        message: _base + 'Дата окончания регистрации'
    });
    if (fd.value.place_info < 4) return emitter.emit("alert", {
        alertType: 'competitionInfo',
        message: _base + 'Место проведения'
    });

    return true;
}

const saveCompInfo = () => {
    if (validation()) {
        isLoading.value = true;
        apiRequest(EVENTS + '/' + route.params.id, fd.value, 'put')
            .then(resp => {
                if (resp && resp.success && resp.message) {
                    console.log('RESPONSE', resp)
                    emitter.emit("success", {alertType: 'competitionInfo', message: resp.message});
                }
            })
            .catch(e => {
                console.log('error', e)
            })
            .finally(() => {
                isLoading.value = false;
            });
    }
}

watch(() => props.data, (newValue) => {
    if (newValue && typeof newValue === 'object' && Object.keys(newValue).length > 0) {
        fd.value.title = newValue.title;
        fd.value.direction_id = newValue.direction_id;
        fd.value.description = newValue.description;
        fd.value.start_at = newValue.start_at;
        fd.value.registration_start_at = newValue.registration_start_at;
        fd.value.end_at = newValue.end_at;
        fd.value.registration_end_at = newValue.registration_end_at;
        fd.value.place_info = newValue.place_info;
        fd.value.organizer = newValue.organizer;
        fd.value.co_organizer = newValue.co_organizer;

        const moscowTimeStart = moment.tz(newValue.registration_start_at, 'UTC').tz('Europe/Moscow');
        fd.value.time_reg_start.minutes = parseInt(moscowTimeStart.format('mm'), 10);
        fd.value.time_reg_start.hours   = parseInt(moscowTimeStart.format('HH'), 10);

        const moscowTimeEnd = moment.tz(newValue.registration_end_at, 'UTC').tz('Europe/Moscow');
        fd.value.time_reg_end.minutes = parseInt(moscowTimeEnd.format('mm'), 10);
        fd.value.time_reg_end.hours   = parseInt(moscowTimeEnd.format('HH'), 10);
    }

}, {deep: true, immediate: true});


</script>